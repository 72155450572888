/*
 * This CSS file _will always_ be called first by all of the sites and therefore
 * you can define wildcard styles that will be used by default. But since CSS
 * follows priority in sequential order, if these styles are re-defined by any
 * other CSS files, they will overwrite the ones in this file, therefore 
 * anything defined here will be a "fall-back" default.
 */

/* Override the default margins defined by various browsers for paragraphs. */
p { margin-top: 0; margin-bottom: 7px; }

/* Do not allow images to have borders by default. */
img { border: none; }

a.mg2_cms{
    color:#009ade;
    text-decoration:none !important;
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
}
.mg2_transitions, .mg2_transitions:before{
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;    
}
a.mg2_cms:hover{
    color:#FF3333 !important;
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
}
a.mg2_cms span{
    display:inline-block;
    vertical-align:middle;
}

label{
    cursor:pointer;
}
form{
    margin-bottom:0;
}